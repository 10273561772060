import React, { useState } from "react"
import Layout from "../components/Layout"
import Modal from "react-bootstrap/Modal"
import balanceSheetImage from "../images/balancesheet.jpg"
import survivorScenarioImage from "../images/survivorscenario.png"
import rightCapitalImage from "../images/rightcapital.jpg"
import planningGuideDownload from "../downloads/SavvyPlanningIntro.pdf"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Planning.scss"

const introToFinancialPlanning = {
    title: "Introduction to financial planning",
    p1:
        "Many research studies confirm the un-surprising conclusion, that those who develop plans are more likely to achieve their goals.",
    p2:
        "But many people are unclear about what exactly a financial plan is, and how to go about developing one. In fact, there are many forms a financial plan might take, from very simple to very complex. The attached introduction illustrates the basic process we follow at Savvy to help you develop a comprehensive personalized financial plan.  Read below for some highlights of the planning process.",
    buttonLabel: "Download our guide",
}

const balanceSheet = {
    title: "Developing your balance sheet",
    p1:
        "Building out your personal balance sheet and cash flow statements is the first essential step in organizing and assessing your current financial position.  This is an example of a personal balance sheet.",
    p2:
        "* All financial documents and information herein represent fictitious sample clients.",
}

const futureForecasting = {
    title: "Forecasting your future",
    p1:
        "The ability to forecast your financial future is one of the most valuable benefits of developing a financial plan.  This allows us to project an estimate of your current path, and also allows us to test alternative scenarios.",
    p2:
        "In the sample at right, this forecast shows that the current path would suffer a catastrophic failure if Jack were to pass substantially before Ann.  Once recognized, we can suggest appropriate changes in the plan to guard against such adverse outcomes.",
}

const planningTech = {
    title: "Financial planning technology",
    content:
        "At Savvy we employ a robust financial planning technology platform to facilitate efficient planning. This platform enhances your planning experience by allowing you to view all of your financial data in one easy place. We begin by setting you up with a personal financial planning account, then all of your financial data is entered into the system. From there we can run a variety of financial forecasts, that you can experiment with as well. The platform also provides budgeting tools, and offers an optional data aggregation service. Data aggregation allows you to link all of your financial accounts to your planning account, which then provides for real time monitoring of how your financial position is evolving over time.",
}

const modalImages = {
    balanceSheet: [balanceSheetImage, "balance sheet example"],
    futureForecasting: [
        survivorScenarioImage,
        "financial planning survival scenario",
    ],
    planningTech: [rightCapitalImage, '"right capital example"'],
}

export default function Planning() {
    const [showModal, setShowModal] = useState(false)
    const [modalImage, setModalImage] = useState("balanceSheet")

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
        <Layout activeTab="planning">
            <div className="planning__page-content">
                <div className="planning__intro-to-planning-title page-title">
                    {introToFinancialPlanning.title}
                </div>
                <div className="planning__intro-to-planning-content">
                    <div className="planning__intro-to-planning-text text-content">
                        <div>{introToFinancialPlanning.p1}</div>
                        <div>{introToFinancialPlanning.p2}</div>
                    </div>
                    <a
                        className="planning__intro-to-planning-button text-content"
                        href={planningGuideDownload}
                        target="_blank"
                        rel="noreferrer"
                        download
                    >
                        {introToFinancialPlanning.buttonLabel}
                    </a>
                </div>
                <div className="planning__subsection planning__balance-sheet">
                    <div className="planning__subsection-content">
                        <div className="planning__subsection-title text-title">
                            {balanceSheet.title}
                        </div>
                        <div className="planning__subsection-text text-content">
                            {balanceSheet.p1}
                        </div>
                        <div className="planning__subsection-text text-content">
                            {balanceSheet.p2}
                        </div>
                    </div>
                    <img
                        className="planning__subsection-img"
                        src={modalImages.balanceSheet[0]}
                        alt={modalImages.balanceSheet[1]}
                        onClick={() => {
                            setModalImage("balanceSheet")
                            setShowModal(true)
                        }}
                    />
                </div>
                <div className="planning__subsection">
                    <div className="planning__subsection-content">
                        <div className="planning__subsection-title text-title">
                            {futureForecasting.title}
                        </div>
                        <div className="planning__subsection-text text-content">
                            {futureForecasting.p1}
                        </div>
                        <div className="planning__subsection-text text-content">
                            {futureForecasting.p2}
                        </div>
                    </div>
                    <img
                        className="planning__subsection-img"
                        src={modalImages.futureForecasting[0]}
                        alt={modalImages.futureForecasting[1]}
                        onClick={() => {
                            setModalImage("futureForecasting")
                            setShowModal(true)
                        }}
                    />
                </div>
                <div className="planning__subsection">
                    <div className="planning__subsection-content">
                        <div className="planning__subsection-title text-title">
                            {planningTech.title}
                        </div>
                        <div className="planning__subsection-text text-content">
                            {planningTech.content}
                        </div>
                    </div>
                    <img
                        className="planning__subsection-img"
                        src={modalImages.planningTech[0]}
                        alt={modalImages.planningTech[1]}
                        onClick={() => {
                            setModalImage("planningTech")
                            setShowModal(true)
                        }}
                    />
                </div>
                <Modal
                    show={showModal}
                    size="lg"
                    onHide={() => setShowModal(false)}
                >
                    <img
                        className="planning__subsection-img--modal"
                        src={modalImages[modalImage][0]}
                        alt={modalImages[modalImage][1]}
                    />
                </Modal>
            </div>
        </Layout>
    )
}
